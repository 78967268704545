<template>
  <div class="w-60 bg-base-200 h-full">
    <div class="px-5 mb-8 pt-8">
      <img class="max-w-[190px]" :src="logoUrl" alt="logo" />
    </div>
    <!-- Sidebar content here -->
    <div class="flex flex-col justify-between px-5 py-4">
      <div class="flex flex-col space-y-2">
        <NuxtLink
          v-if="session.tenant?.type === TenantType.Distributor"
          :to="{ name: 'tenant-policies-new' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgClaimsIcon></ImgClaimsIcon>

          New policy
        </NuxtLink>

        <NuxtLink
          :to="{ name: 'tenant-policies' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgPolicyIcon></ImgPolicyIcon>
          Policies
        </NuxtLink>

        <NuxtLink
          :to="{ name: 'tenant-insurlets' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgInsurletIcon></ImgInsurletIcon>

          Insurlets
        </NuxtLink>

        <NuxtLink
          :to="{ name: 'tenant-distributors' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgDistributorIcon></ImgDistributorIcon>
          Distributors
        </NuxtLink>

        <NuxtLink
          :to="{ name: 'tenant-insurers' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgInsurersIcon></ImgInsurersIcon>
          Insurers
        </NuxtLink>
        <NuxtLink
          v-if="isDevelop"
          :to="{ name: 'tenant-billing' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgRevenueIcon></ImgRevenueIcon>

          Revenue Sharing
        </NuxtLink>
        <NuxtLink
          v-if="isDevelop"
          :to="{ name: 'tenant-claims' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgClaimsIcon></ImgClaimsIcon>
          Claims
        </NuxtLink>
        <hr />
        <NuxtLink
          :to="{ name: 'tenant-developers' }"
          @click="menuClicked"
          class="menu-item group"
        >
          <ImgDeveloperIcon></ImgDeveloperIcon>
          Developer Hub
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useSessionStore } from "@/stores/session";
  import { TenantType } from "~/graphql";

  const session = useSessionStore();
  const isDevelop = computed(() => {
    const env = getCurrentEnvironment();

    return developEnvironments.includes(env as Develop);
  });

  const emit = defineEmits<{
    "menu-clicked": [name: string];
  }>();

  const menuClicked = () => {
    let name = "unknown";
    if (useRoute().name) {
      name = useRoute().name!.toString();
    }
    emit("menu-clicked", name);
  };

  const logoUrl = ref<string>(
    useTenantAssetLogoUrl(useRoute().params.tenant as string)
  );
</script>

<style scoped>
  .menu-item {
    @apply flex gap-1 items-center rounded-md px-1 py-1 hover:text-primary-content  hover:bg-[color-mix(in_oklab,oklch(var(--p)),black_7%)] transition-all duration-200;
  }

  .router-link-active.router-link-exact-active {
    @apply bg-primary text-primary-content;
  }

  .router-link-active.router-link-exact-active svg {
    @apply text-primary-content;
  }
</style>
